import * as styles from "./Header.module.scss";

import { Link, LinkData } from "../Link/Link";

import CasioLogo from "../../assets/svg/casio-logo.svg";
// import ClassPadLogo from "../../assets/svg/classpad-logo.svg";
import CasioEducationLogo from "../../assets/svg/casio-education-logo-white.svg";
import { Link as GatsbyLink } from "gatsby";
import { MainNavigation } from "../MainNavigation/MainNavigation";
import React from "react";
import { getBasePathForLanguage } from "../../../sanity/plugins/multilanguage";

export type HeaderData = {
  navigation: LinkData[];
  registerCta: string;
  loginCta: string;
};

interface HeaderProps {
  data?: HeaderData;
  language: string;
  availableLanguages?: string[];
}

export const Header: React.FC<HeaderProps> = ({
  data,
  language,
  availableLanguages,
}) => {
  return (
    <header className={styles.header}>
      <GatsbyLink
        className={styles.header__brand}
        to={getBasePathForLanguage(language)}
      >
        <CasioLogo className={styles.header__brand__casio} />
        {/* <ClassPadLogo className={styles.header__brand__classpad} /> */}
        <CasioEducationLogo className={styles.header__brand__classpad} />
      </GatsbyLink>
      <MainNavigation
        language={language}
        registerCta={data?.registerCta}
        loginCta={data?.loginCta}
        availableLanguages={availableLanguages}
      >
        {data?.navigation?.map((item, index) => {
          return (
            <li key={index}>
              <Link to={item}></Link>
            </li>
          );
        })}
      </MainNavigation>
    </header>
  );
};
